#navbar {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.nav-item {
  padding: 0px;
}

.navbar-collapse {
  padding: 0px;
}

.dropdown-toggle {
  padding: 0px;
}

.nav-reminder {
  padding-left: 10px;
  margin-top: -5px;
}

.ant-drawer-header {
  border-radius: 0;
}

div .ant-drawer-body {
  color: #fff;
  height: calc(100vh - 55px);
  padding: 0px;
}

.divStyle {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
}

.divStyle:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

/* ---------------------------------------------------------- */
.header {
  color: #fff;
  display: flex;
  align-items: center;
  height: 70px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.menu-toggle {
  cursor: pointer;
}

.menu {
  height: 70px;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;  
  cursor: pointer;
  height: 70px;
  display: flex;
}

.menu li {
  position: relative;
  height: 70px;
  /* width: 130px; */
  display: flex;
  align-items: center;
  padding-right: 24px;
  padding-left: 24px;
}

.menu li:hover {
  position: relative;
  /* text-decoration: underline; */
  background: rgba(0, 0, 0, 0.2);
}

.popup {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  top: 70px;
  left: 0px;
  width: 180%;
  height: 265%;
}

.popup ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.popup li {
  margin: 5px 0;
  padding: 6px;
  padding: 6px;
  color: #0085FF;
  height: 25px;
  width: 95%;
}

.popup li:hover {
  text-decoration: underline;
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .menu {
    display: block;
  }

  .menu ul {
    display: flex;
    /* gap: 30px; */
  }

  .menu li {
    margin: 0;
  }

  .popup ul {
    display: block;
  }
}

